export enum ModalName {
  SignIn = "sign-in",
  SignUp = "sign-up",
  Auth = "auth",
  Logout = "logout",
  Donation = "donation",
  BalanceTopUp = "balance-top-up",
  BalanceWithdraw = "balance-withdraw",
  StreamAccess = "stream-access",
  BecomePaxer = "become-paxer",
  EmbeddingVideo = "embedding-video",
  HirePaxer = "hire-paxer",
  HirePaxerAccess = "hire-paxer-access",
  Feedback = "feedback",
  OrderCancel = "order-cancel",
  OrderCancelFail = "order-cancel-fail",
  OrderAutoCancellation = "order-auto-cancellation",
  OrderPayment = "order-payment",
  HowIsItWork = "how-is-it-work",
  Prolongation = "order-prolongation",
  AccountDelete = "account-delete",
  Onboarding = "onboarding",
  MicrophonePermissions = "microphone-permissions",
  MonetizeYourTime = "monetize",
  TelegramLinked = "telegram-linked"
}

export const DEFAULT_DONATIONS = [5, 10, 50];
export const MIN_TOP_UP_AMOUNT = 5;
export const MAX_TOP_UP_AMOUNT = 10000;
