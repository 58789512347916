import { getAge } from "utils/DateUtils";
import { PrivateProfileRawData } from "types/profile";
import { PublicProfile } from "./PublicProfile";

export class PrivateProfile extends PublicProfile {
  birthDate?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  age?: number;
  telegramID?: string;

  constructor(data: PrivateProfileRawData) {
    super(data);
    this.birthDate = data.birth_date;
    this.email = data.email;
    this.firstName = data.first_name || '';
    this.lastName = data.last_name || '';
    this.fullName = [data.first_name, data.last_name].join(" ").trim();
    this.telegramID = data.telegram_id || '';

    if (this.birthDate) {
      this.age = getAge(this.birthDate);
    }
  }
}
