import { ResponseStatus } from "types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { getPaxerList, getFilteredPaxerList, getTopUsers } from "services/ProfileService";
import { User } from "models";
import { UsersFilters } from "types/users";
import { Pagination } from "types/request";

export interface UsersState {
  usersStatus: ResponseStatus;
  users: User[] | null;
  topUsersStatus: ResponseStatus;
  topUsers: User[];
}

const initialState: UsersState = {
  usersStatus: ResponseStatus.LOADING,
  users: [],
  topUsersStatus: ResponseStatus.LOADING,
  topUsers: [],
};

export const getTopUsersAsync = createAsyncThunk("users/getTopUsersAsync", async () => {
  const response = await getTopUsers();

  return response.map((profile) => new User(profile));
});

export const getUsersAsync = createAsyncThunk("users/getUsersAsync", async (params: Pagination) => {
  const resp = await getPaxerList(params);

  if (resp.profiles) {
    return resp.profiles.map((profile) => new User(profile));
  }
});

export const getFilteredUsersAsync = createAsyncThunk("users/getFilteredUsersAsync", async (filters: Pagination & UsersFilters) => {
  const resp = await getFilteredPaxerList(filters);

  if (resp.profiles) {
    return resp.profiles.map((profile) => new User(profile));
  }
});

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUsersList: (state) => {
      state.users = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending, (state) => {
        state.usersStatus = ResponseStatus.LOADING;
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.usersStatus = ResponseStatus.IDLE;
        state.users = action.payload;
      })
      .addCase(getUsersAsync.rejected, (state) => {
        state.usersStatus = ResponseStatus.FAILED;
      })

      .addCase(getFilteredUsersAsync.pending, (state) => {
        state.usersStatus = ResponseStatus.LOADING;
      })
      .addCase(getFilteredUsersAsync.fulfilled, (state, action) => {
        state.usersStatus = ResponseStatus.IDLE;
        state.users = [...state.users, ...(action.payload || [])];
      })
      .addCase(getFilteredUsersAsync.rejected, (state) => {
        state.usersStatus = ResponseStatus.FAILED;
      })

      .addCase(getTopUsersAsync.pending, (state) => {
        state.topUsersStatus = ResponseStatus.LOADING;
      })
      .addCase(getTopUsersAsync.fulfilled, (state, action) => {
        state.topUsersStatus = ResponseStatus.IDLE;
        state.topUsers = action.payload;
      })
      .addCase(getTopUsersAsync.rejected, (state) => {
        state.topUsersStatus = ResponseStatus.FAILED;
      });
  },
});

export const usersSelectors = {
  usersStatus: (state: RootState) => state.users.usersStatus,
  isUsersLoading: (state: RootState) => state.users.usersStatus === ResponseStatus.LOADING,
  users: (state: RootState) => state.users.users,
  topUsers: (state: RootState) => state.users.topUsers,
  isTopUsersStatusLoading: (state: RootState) => state.users.topUsersStatus === ResponseStatus.LOADING,
};

export const { resetUsersList } = usersSlice.actions;

export default usersSlice.reducer;
